<template>
<v-container>
  <v-card>
    <v-card-title>Listings</v-card-title>
    <v-card-text>
      <v-data-table
          :items="listings"
          :headers="listingHeaders"
          :loading="loading"
          items-per-page="-1"
      >
        <template v-slot:item.id="{item}">
          <v-btn text :to="'/listings/' + item.id">{{item.id}}</v-btn>
        </template>
        <template v-slot:item.thumbnailUrl="{item}">
          <v-avatar size="24" color="secondary">
            <img :src="item.thumbnailUrl" v-if="item.thumbnailUrl !== null">
            <span class="white--text" v-else>{{item.name.substr(0,1)}}</span>
          </v-avatar>
        </template>
        <template v-slot:item.name="{item}">
          <div @click="navigate('/listings/' + item.id)">{{item.name}}</div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="getListings" icon><v-icon>mdi-refresh</v-icon></v-btn>
    </v-card-actions>
    <v-card-text>
      <v-icon v-if="loadingComplete">mdi-pencil</v-icon>
      <v-icon v-else>mdi-delete</v-icon>
      <table>
        <tr>
          <td>ID</td>
          <td>Name</td>
          <td>Jotform Prefill</td>
        </tr>
        <tr v-for="listing in listingsComplete" :key="listing.id">
          <td>{{ listing.id }}</td>
          <td>{{ listing.name }}</td>
          <td>{{ listing.jotformPrefill }}</td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";

export default {
  name: "Listings",
  data: () => ({
    loading: false,
    loadingComplete: false,
    listingHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Thumbnail', value: 'thumbnailUrl' },
      { text: 'Name', value: 'name' },
      { text: 'Adresse', value: 'address' },
      { text: 'Straße', value: 'street' },
      { text: 'Stadt', value: 'city' },
      { text: 'Kapazität', value: 'personCapacity' },
    ],
    listings: [],
    listingsComplete: [],
  }),
  watch: {
    listings: {
      deep: true,
    },
  },
  methods: {
    getListings() {
      this.loading = true;
      axios.get( process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listings").then((res) => {
        console.log(res.data)
        this.listings = res.data.result
        this.loading = false
      })
    },
    async getListingByID(id) {
      axios.get(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listings/" + id).then((res) => {
        console.log(res.data)
        return res.data.result;
      }).catch(()=>{
        return null;
      })
    },
    async getListingByIDCustomField(id) {
      let res = await axios.get(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listings/" + id);

      console.log(res.data)
      let listing = res.data.result
      let customfield = listing.customFieldValues.filter(function( obj ) {
        return obj.customFieldId == 50045;
      });
      if(customfield.length == 1) {
        return customfield[0].value;
      }
      return "-";
    },
    getListingsComplete() {
      this.loadingComplete = true;
      axios.get( process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listingsComplete").then((res) => {
        console.log(res.data)
        this.listingsComplete = res.data
        this.loadingComplete = false
      })
    },
    async getJotformPrefill(listing) {
      let result = await this.getListingByIDCustomField(listing.id)
      console.log("result")
      console.log(result)
      listing.jotformPrefillUrl = result;
      return result;
      /*let result = this.getListingByID(listing.id)
      if (result == null) {
        return "---"
      }*/

    },
  },
  created() {
    this.getListings();
    this.getListingsComplete();
  }
}
</script>

<style scoped>

</style>