<template>
<v-container>
  <v-card>
    <v-progress-linear
        indeterminate
        v-if="loading"
    ></v-progress-linear>
    <v-card-title><v-btn to="/listings" icon><v-icon>mdi-arrow-left</v-icon></v-btn>&nbsp;Listing {{id}}</v-card-title>
    <v-card-subtitle v-if="listing">{{listing.name}}</v-card-subtitle>
    <v-card-text>
      {{listing.internalListingName}}
    </v-card-text>
    <v-card-text v-if="listing">
      <v-text-field label="Jotform Prefill Url" v-model="jotformPrefillUrl" outlined></v-text-field>
    </v-card-text>
    <v-card-actions v-if="listing">
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="updateListing">Update</v-btn>
    </v-card-actions>
    <v-card-text v-if="listing">
      <v-row v-for="cf in listing.customFieldValues" :key="cf.id">
        <v-col cols="4">{{cf.customField.name}}</v-col>
        <v-col cols="8" v-if="cf.customField.name == 'Custom Jotform Link'"><a :href="cf.value">{{cf.value}}</a></v-col>
        <v-col cols="8" v-else>{{cf.value}}</v-col>
      </v-row>
      <pre>{{listing}}</pre>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";

export default {
  name: "ListingDetails",
  data: () => ({
    id: "",
    loading: false,
    listing: null,
    jotformPrefillUrl: null,
  }),
  methods: {
    getListing(id) {
      this.loading = true
      axios.get(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listings/" + id).then((res) => {
        console.log(res.data)
        this.listing = res.data.result
        let customfield = this.listing.customFieldValues.filter(function( obj ) {
          return obj.customFieldId == 50045;
        });
        if(customfield.length == 1) {
          this.jotformPrefillUrl = customfield[0].value;
        }
        this.loading = false
      })
    },
    updateListing() {
      // CF ID: 50045
      this.listing.customFieldValues = this.listing.customFieldValues.filter(function( obj ) {
        return obj.customFieldId !== 50045;
      });
      if(this.jotformPrefillUrl !== null && this.jotformPrefillUrl !== "") {
        this.listing.customFieldValues.push({
          "customFieldId" : 50045,
          "value" : this.jotformPrefillUrl,
        })
      }
      delete this.listing["bookingcomPropertyName"];
      axios.put(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listings/" + this.id, this.listing).then((res) => {
        console.log(res.data)
        this.getListing(this.id)
      })
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.getListing(this.id);
  },
}
</script>

<style scoped>

</style>